import React from "react"
import css from "./index.module.scss"
import fontType from "../../../../helpers/fontType.module.scss"

import Grid from "../../../Grid"

import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import ACCOUNT_MANAGER_ICON from "../../../../assets/images/icn-account-manager.svg"
import PROTECTION_ICON from "../../../../assets/images/icn-FraudProtection.svg"
import SUPPORT_ICON from "../../../../assets/images/icn-ChatSupport.svg"
import TARGET_ICON from "../../../../assets/images/icn-target.svg"
import SUPERCHARGED_ICON from "../../../../assets/images/icn-supercharged.svg"

const query = graphql`
  query {
    banner: file(relativePath: { eq: "images/introbanner.png" }) {
      childImageSharp {
        fixed(height: 300) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const AdditionalFeatures = () => {
  const { banner } = useStaticQuery(query)
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <h2 className={css.title}>
        With you. <br />
        Every step of the way.
      </h2>
      <div className={css.content}>
        <div className={css.text}>
          <ul className={css.items}>
            <li>
              <img src={ACCOUNT_MANAGER_ICON} alt="" height="300" width="300" />
              <p className={fontType.b1}>
                Dedicated Account <br />
                Manager
              </p>
              <div className={css.feature_body}>
                <p className={css.body}>
                  You’ll have a single point of contact to ensure your team is
                  getting the most out of ReferralCandy.
                </p>
              </div>
            </li>
            <li>
              <img src={SUPPORT_ICON} alt="" height="300" width="300" />
              <p className={fontType.b1}>
                Live Chat <br />
                Support
              </p>
              <div className={css.feature_body}>
                <p className={css.body}>
                  You’ll get priority access to our customer solutions and
                  support teams for questions and guidance on best practice.
                </p>
              </div>
            </li>
            <li>
              <img src={TARGET_ICON} alt="" height="300" width="300" />
              <p className={fontType.b1}>
                Customized <br />
                Features
              </p>
              <div className={css.feature_body}>
                <p className={css.body}>
                  You get direct access to our core engineers for development
                  support and custom requests for your business.
                </p>
              </div>
            </li>
            <li>
              <img src={PROTECTION_ICON} alt="" height="300" width="300" />
              <p className={fontType.b1}>
                Enterprise-grade <br />
                Security
              </p>
              <div className={css.feature_body}>
                <p className={css.body}>
                  We implement best practice, controls and security measures to
                  ensure your customer data is safe with us.
                </p>
              </div>
            </li>
            <li>
              <img src={SUPERCHARGED_ICON} alt="" height="300" width="300" />
              <p className={fontType.b1}>
                Supercharged <br />
                Integrations
              </p>
              <div className={css.feature_body}>
                <p className={css.body}>
                  You get pre-built integrations that make setup completely
                  painless. Integrate seamlessly with Shopify, Magento,
                  BigCommerce, WooCommerce, Volusion, Celery, 3dcart, ReCharge
                  and other bespoke platforms.
                </p>
              </div>
            </li>
            <li></li>
          </ul>
        </div>
        <div className={css.image}>
          <Img fixed={banner.childImageSharp.fixed} alt="" />
        </div>
      </div>
    </Grid>
  )
}

export default AdditionalFeatures
