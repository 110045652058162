import React, { PureComponent } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import classnames from "classnames"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"

import Grid from "../../../Grid"

import css from "./index.module.scss"

const SECTIONS = [
  {
    index: 0,
    key: "secretlab",
    quote:
      "We looked into several referral software. ReferralCandy was the only one fulfilled our needs for multiple international stores, functionality, and incredible customer support.",
    cite: "Ian Ang",
    position: "Co-founder, Secretlab",
  },
  {
    index: 1,
    key: "riffraff",
    quote:
      "One of the main reasons I chose ReferralCandy is the simple ‘set and forget’ process. I can go about the rest of my marketing knowing that ReferralCandy is doing all the promotion, monitoring and recording.",
    cite: "Emma Kruger",
    position: "CEO, Riff Raff & Co",
  },
]

const TESTIMONIAL_CLASSNAMES = {
  enter: css["in"],
  enterActive: css.in_active,
  exit: css["out"],
  exitActive: css.out_active,
}
const ANIMATION_TOTAL_TIME = 1250

const DEFAULT_SECTION = SECTIONS[0]
const ROTATE_TIME = 20000

const extractSectionFromHash = () => {
  const match =
    window.location.hash && window.location.hash.match(/^#testimonial-(.+)$/)
  return match
    ? SECTIONS.find((section) => section.key === match[1])
    : DEFAULT_SECTION
}

class Testimonials extends PureComponent {
  state = {
    section: DEFAULT_SECTION,
    // Dynamic property represents whether or not its server side rendered or not
    // When its server side rendered, we want all the content to be available (SEO reasons)
    // When its loaded, it's hidden behind a carousel click
    dynamic: false,
  }
  nextSectionTimeout = null

  onSectionClick = (section) => (e) => {
    e.preventDefault()
    this.setState({ section: section })
    this.scheduleNextTestimonial()
  }

  scheduleNextTestimonial = () => {
    clearTimeout(this.nextSectionTimeout)
    this.nextSectionTimeout = setTimeout(() => {
      const index = SECTIONS.indexOf(this.state.section)
      const nextIndex = index >= SECTIONS.length - 1 ? 0 : index + 1
      this.setState({ section: SECTIONS[nextIndex] })
      this.scheduleNextTestimonial()
    }, ROTATE_TIME)
  }

  waitForScrollIntoView = () => {
    if (!this.navRef) return
    const target = this.navRef
    const observer = new IntersectionObserver(
      (entries) => {
        const visible = entries[0] && entries[0].isIntersecting
        if (visible) {
          this.scheduleNextTestimonial()
        } else {
          clearTimeout(this.nextSectionTimeout)
        }
      },
      {
        threshold: 0.25,
      }
    )
    observer.observe(target)

    this.clearScrollIntoView = () => observer.unobserve(target)
  }

  componentDidMount() {
    const section = extractSectionFromHash()
    this.setState({
      section: section,
      dynamic: true,
    })
    this.waitForScrollIntoView()
  }

  componentWillUnmount() {
    clearTimeout(this.nextSectionTimeout)
    if (this.clearScrollIntoView) this.clearScrollIntoView()
  }

  render() {
    const activeSection = this.state.section || DEFAULT_SECTION
    const previousSection = SECTIONS[activeSection.index - 1] || activeSection
    const nextSection = SECTIONS[activeSection.index + 1] || activeSection
    const renderedSections = this.state.dynamic ? [activeSection] : SECTIONS
    const data = this.props.data

    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <ul className={css.nav} ref={(ref) => (this.navRef = ref)}>
          {SECTIONS.map((section) => (
            <li key={section.key}>
              <a
                href={`#testimonial-${section.key}`}
                className={activeSection === section ? css.active : ""}
                onClick={this.onSectionClick(section)}
                aria-label={section.key}
              ></a>
            </li>
          ))}
        </ul>
        <div className={css.middle_section}>
          <FaAngleLeft
            className={css.arrow}
            onClick={this.onSectionClick(previousSection)}
          />
          <TransitionGroup className={css.contents}>
            {renderedSections.map((section) => {
              const key = section.key
              return (
                <CSSTransition
                  classNames={TESTIMONIAL_CLASSNAMES}
                  timeout={ANIMATION_TOTAL_TIME}
                  key={key}
                >
                  <div
                    className={classnames(
                      css.content,
                      css[`testimonial-${key}`]
                    )}
                    id={`testimonial-${key}`}
                  >
                    <div className={css.images}>
                      <div className={css.picture}>
                        <Img
                          fluid={data[`picture_${key}`].childImageSharp.fluid}
                          className={css.gatsby_image}
                        />
                      </div>
                    </div>
                    <blockquote className={css.quote}>
                      <p className={css.quote_text}>{section.quote}</p>
                      <footer>
                        <div className={css.avatar_container}>
                          <Img
                            fluid={data[`avatar_${key}`].childImageSharp.fluid}
                          />
                        </div>
                        <cite>
                          <p>{section.cite}</p>
                          <p>{section.position}</p>
                        </cite>
                      </footer>
                    </blockquote>
                  </div>
                </CSSTransition>
              )
            })}
          </TransitionGroup>
          <FaAngleRight
            className={css.arrow}
            onClick={this.onSectionClick(nextSection)}
          />
        </div>
        <ul className={css.bottom_nav} ref={(ref) => (this.navRef = ref)}>
          {SECTIONS.map((section) => (
            <li key={section.key}>
              <a
                href={`#testimonial-${section.key}`}
                className={activeSection === section ? css.active : ""}
                onClick={this.onSectionClick(section)}
                aria-label={section.key}
              >
                <div className={css.blob} />
              </a>
            </li>
          ))}
        </ul>
      </Grid>
    )
  }
}

const query = graphql`
  query {
    picture_secretlab: file(
      relativePath: { eq: "images/testimonials/picture-secretlab.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    picture_riffraff: file(
      relativePath: {
        eq: "images/testimonials/picture-riffraff-enterprise.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    avatar_secretlab: file(
      relativePath: { eq: "images/testimonials/avatar-secretlab.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    avatar_riffraff: file(
      relativePath: { eq: "images/testimonials/avatar-riff-raff.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <Testimonials data={data} {...props} />}
  />
)
