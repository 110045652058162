import React from "react"
import css from "./index.module.scss"
import fontType from "../../../../helpers/fontType.module.scss"
import Grid from "../../../Grid"
import Button from "../../../Button"

import { FaArrowRight } from "react-icons/fa"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query {
    banner: file(
      relativePath: { eq: "images/enterprise/banner-enterprise.png" }
    ) {
      childImageSharp {
        fixed(height: 380) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const Main = () => {
  const { banner } = useStaticQuery(query)

  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.text}>
        <p className={fontType.b1}>ReferralCandy for Enterprise</p>
        <h1>
          Referral <br />
          marketing for <br />
          million dollar <br />
          businesses
        </h1>
        <p>
          <Button
            purpose="primary"
            href="https://help.referralcandy.com/support/tickets/new"
            target="_blank"
          >
            Let's Talk &nbsp; <FaArrowRight />
          </Button>
        </p>
      </div>
      <div className={css.image}>
        <Img fixed={banner.childImageSharp.fixed} alt="Shopify Plus banner" />
      </div>
    </Grid>
  )
}

export default Main
