import React from "react"

import Layout from "../components/Layout"
import Main from "../components/Sections/Enterprise/Main"
import OnlineStores from "../components/Sections/Enterprise/OnlineStores"
import AdditionalFeatures from "../components/Sections/Enterprise/AdditionalFeatures"
import Testimonials from "../components/Sections/Enterprise/Testimonials"
import Stats from "../components/Sections/Stats"

const metadata = {
  title: "Enterprise | ReferralCandy",
}
const EnterprisePage = () => {
  return (
    <Layout metadata={metadata}>
      <Main />
      <OnlineStores />
      <Stats />
      <Testimonials />
      <AdditionalFeatures />
    </Layout>
  )
}

export default EnterprisePage
